import React, { PureComponent } from "react";
import posed, { PoseGroup } from "react-pose";

import { renderFluidImage } from "utils/imageHelpers";
import { sine } from "utils/easing";

const RotatingImage = posed.div({
  enter: { opacity: 1, type: `tween`, ease: sine.out, duration: 400 },
  exit: { opacity: 0, type: `tween`, ease: sine.in, duration: 400 },
});

class RotatingAuthorImages extends PureComponent {
  rotationTimeout = null;

  constructor(props) {
    super(props);
    this.images = props.authors
      .map((a) => a?.data?.image?.carousel || null)
      .filter(Boolean);
    this.state = { currentIndex: 0 };
  }

  change = () => {
    let currentIndex = this.state.currentIndex + 1;
    if (currentIndex >= this.images.length) currentIndex = 0;
    this.setState({ currentIndex }, () => {
      this.rotationTimeout = setTimeout(this.change, 5000);
    });
  };

  componentDidMount() {
    this.rotationTimeout = setTimeout(this.change, 6500);
  }

  componentWillUnmount() {
    clearTimeout(this.rotationTimeout);
  }

  render() {
    const { currentIndex } = this.state;
    return (
      <PoseGroup animateOnMount={false}>
        <RotatingImage key={`RotatingAuthorImage-${currentIndex}`}>
          {this.images[currentIndex]
            ? renderFluidImage(this.images[currentIndex])
            : null}
        </RotatingImage>
      </PoseGroup>
    );
  }
}

export default RotatingAuthorImages;
