let _apiInitted = false;
let _apiInitting = false;
let cbs = [];

const loadYoutubeAPI = (cb, scope = null) => {
  if (typeof window === `undefined`) return;
  const ytIframeTag = document.getElementById('yt-iframe');

  if (_apiInitted && ytIframeTag && ytIframeTag !== undefined) {
    cb.call(scope);
    return;
  }

  cbs.push({ cb, scope });

  if (!_apiInitting) {
    _apiInitting = true;
    let tag = document.createElement('script');
    tag.id = 'yt-iframe';
    tag.src = '//www.youtube.com/iframe_api';

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    window.onYouTubeIframeAPIReady = () => {
      console.log('yt iframe api ready');
      _apiInitted = true;
      _apiInitting = false;

      while (cbs.length > 0) {
        let cbItem = cbs.shift();
        cbItem.cb.call(cbItem.scope);
      }
    };
  }
  // init videos on youtube api ready event
};

export default loadYoutubeAPI;
