import React, { PureComponent } from "react";

import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import Section from "react-bulma-components/lib/components/section";
import YTPlayer from "components/YTPlayer";
import classNames from "classnames/bind";
import styles from "./Video.module.scss";
import uid from "utils/uid";

const cx = classNames.bind(styles);

const getYoutubeVideoIdFromEmbedUrl = (url) => {
  var ID = "";
  url = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/|list=|\/list\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_-]/i);
    ID = ID[0];
  } else {
    ID = url;
  }
  return ID;
};

export class VideoSection extends PureComponent {
  state = { videoData: null };

  setVideoData = (data) => {
    this.setState({ videoData: data });
  };

  render() {
    const { primary } = this.props;
    let theme = primary.theme || `grey`;
    let hasHeading =
      primary.heading &&
      primary.heading.text &&
      primary.heading.text.length > 0;
    let showTitle = primary.showTitle === "true" || hasHeading;

    let title =
      primary.heading && primary.heading.text && primary.heading.text.length > 0
        ? primary.heading.text
        : this.state.videoData && this.state?.videoData?.snippet
        ? this.state?.videoData?.snippet?.title
        : null;
    return (
      <Section className={`${theme} ${cx({ [theme]: true, section: true })}`}>
        <Container>
          <Columns mobile multiline>
            {showTitle && title && (
              <Columns.Column
                mobile={{ size: 12 }}
                tablet={{ size: 12 }}
                className={styles.videoWrapper}
              >
                <h4 className={cx({ title: true, heading: hasHeading })}>
                  {title}
                </h4>
              </Columns.Column>
            )}
            <Video {...this.props} setVideoData={this.setVideoData} />
          </Columns>
        </Container>
      </Section>
    );
  }
}

export default class Video extends PureComponent {
  state = { embedSize: `56.25%` };

  constructor(props) {
    super(props);
    this.uid = `video-${uid()}`;
  }

  componentDidMount() {
    if (typeof window === "undefined") {
      return;
    }
    window.addEventListener("resize", this.onResize);
    this.setState({ embedSize: this.getEmbedSize() });
  }

  componentWillUnmount() {
    if (typeof window === "undefined") {
      return;
    }
    window.removeEventListener("resize", this.onResize);
  }

  onResize = () => {
    this.setState({ embedSize: this.getEmbedSize() });
  };

  onPlayVideo = () => {
    if (this.props.onPlayVideo) {
      this.props.onPlayVideo();
    }
  };

  onStopVideo = () => {
    if (this.props.onStopVideo) {
      this.props.onStopVideo();
    }
  };

  onEndVideo = () => {
    if (this.props.onEndVideo) {
      this.props.onEndVideo();
    }
  };

  getEmbedSize = () => {
    const { primary } = this.props;
    if (primary.embed_ratio && primary.embed_ratio !== "") {
      let aRatio = primary.embed_ratio.split(":");
      if (aRatio && aRatio.length === 2) {
        let w = parseFloat(aRatio[0]);
        let h = parseFloat(aRatio[1]);
        return `${(h / w) * 100}%`;
      }
    }
    if (!primary.embed_url.width) {
      return `56.25%`;
    }
    return `${(primary.embed_url.height / primary.embed_url.width) * 100}%`;
  };

  render() {
    const {
      primary,
      marginBottom = null,
      opacity = null,
      stopped,
    } = this.props;
    const { embedSize } = this.state;
    const isList = primary?.embed_url?.embed_url?.indexOf("playlist") >= 0;

    return (
      <Columns.Column
        mobile={{ size: 12 }}
        tablet={{ size: 12 }}
        className={styles.videoWrapper}
        style={{
          marginBottom,
          opacity,
        }}
      >
        <div className={styles.video} ref={(r) => (this.base = r)}>
          <div
            className={styles.player}
            style={{
              paddingBottom: embedSize,
            }}
          >
            {primary.iframe_embed && primary.iframe_embed.length > 0 ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: primary.iframe_embed,
                }}
              />
            ) : primary.embed_url &&
              (primary.embed_url.provider_name === "YouTube" ||
                primary.embed_url?.embed_url?.indexOf("youtube") >= 0) ? (
              <YTPlayer
                stopped={stopped === undefined ? false : stopped}
                autoPlay={this.props.autoPlay === true}
                onPlayVideo={this.onPlayVideo}
                onStopVideo={this.onStopVideo}
                onEndVideo={this.onEndVideo}
                videoId={getYoutubeVideoIdFromEmbedUrl(
                  primary.embed_url.embed_url
                )}
                setVideoData={this.props.setVideoData}
                isList={isList}
              />
            ) : null}
          </div>
        </div>
      </Columns.Column>
    );
  }
}
