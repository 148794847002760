import React, { Component } from "react";

import RotatingAuthorImages from "components/RotatingAuthorImages";
import classNames from "classnames/bind";
import getAuthorNames from "utils/getAuthorNames";
import { navigate } from "gatsby";
import posed from "react-pose";
import { renderFluidImage } from "utils/imageHelpers";
import styles from "./DialogCarousel.module.scss";

const cx = classNames.bind(styles);

const PreviewFigure = posed.figure({
  on: { opacity: 1, applyAtStart: { display: "flex" } },
  off: { opacity: 0, applyAtEnd: { display: "none" } },
});

class DialogCarousel extends Component {
  state = { currentIndex: 0, nextShowing: false, prevShowing: false };

  static getDerivedStateFromProps(props, state) {
    let currentIndex;
    if (props.url && props.urls) {
      currentIndex = props.urls.indexOf(props.url);
    }
    if (
      currentIndex === null ||
      currentIndex === undefined ||
      currentIndex < 0
    ) {
      currentIndex = 0;
    }
    if (currentIndex === state.currentIndex) {
      return null;
    }
    return { currentIndex };
  }

  next = () => {
    const { baseUrl, urls, items } = this.props;
    const { currentIndex } = this.state;
    if (urls && urls.length) {
      let url = urls[Math.min(items.length - 1, currentIndex + 1)];
      navigate(`${baseUrl || `/`}/${url}/`, { state: { modal: true } });
    } else {
      this.setState({ currentIndex: Math.min(items.length, currentIndex + 1) });
    }
  };

  prev = () => {
    const { baseUrl, urls } = this.props;
    const { currentIndex } = this.state;
    if (urls && urls.length) {
      let url = urls[Math.max(0, currentIndex - 1)];
      navigate(`${baseUrl || `/`}/${url}/`, { state: { modal: true } });
    } else {
      this.setState({ currentIndex: Math.max(0, currentIndex - 1) });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentIndex !== prevState.currentIndex) {
      this.wrapper.scroll(0, 0);
    }
  }

  renderName(item) {
    if (!item) {
      return "";
    }
    if (item.first_name) {
      return `${item.first_name.text} ${item.last_name.text}`;
    } else if (item.finalist) {
      if (
        item.finalist.finalistData &&
        !item.finalist.finalistData.forceAuthor &&
        item.finalist.finalistData.book &&
        item.finalist.finalistData.book.document
      ) {
        return getAuthorNames(
          item.finalist.finalistData.book.document[0].data.authors.map((a) => ({
            author: a.author1.document[0],
            role: a.type,
          }))
        );
      }
      return this.renderName(item.finalist.data);
    } else if (item.title && item.title.text) {
      return `${item.title.text}`;
    } else if (item.book) {
      return this.renderName(item.book.data);
    }

    return null;
  }

  renderImage(item) {
    if (!item) {
      return null;
    }
    if (item.finalist) {
      if (
        item.finalist.finalistData &&
        !item.finalist.finalistData.forceAuthor &&
        item.finalist.finalistData.book &&
        item.finalist.finalistData.book.document &&
        item.finalist.finalistData.book.document[0].data.authors.length > 1
      ) {
        return (
          <div style={{ width: `64.5756px`, height: 100 }}>
            <RotatingAuthorImages
              authors={item.finalist.finalistData.book.document[0].data.authors.map(
                (a) => a.author1.document[0]
              )}
            />
          </div>
        );
      }
      return this.renderImage(item.finalist.data);
    }
    if (item.book) {
      return this.renderImage(item.book.data);
    }
    if (item.cover_image) {
      item.image = item.cover_image;
    }
    if (item.image) {
      const img = item.image.carousel || item.image;
      const aspectRatio =
        img && img?.dimensions
          ? img.dimensions.width / img.dimensions.height
          : 327 / 507;
      return renderFluidImage(
        img,
        null,
        {
          style: { width: `${100 * aspectRatio}px`, height: 100 },
        },
        "writer"
      );
    }
    return null;
  }

  showNext = () => {
    this.setState({ nextShowing: true });
  };

  hideNext = () => {
    this.setState({ nextShowing: false });
  };

  showPrev = () => {
    this.setState({ prevShowing: true });
  };

  hidePrev = () => {
    this.setState({ prevShowing: false });
  };

  render() {
    const { items } = this.props;
    const { currentIndex } = this.state;

    const prevItem = items[currentIndex - 1];
    const nextItem = items[currentIndex + 1];
    return (
      <React.Fragment>
        <div className={styles.wrapper} ref={(r) => (this.wrapper = r)}>
          <article className={styles.item}>{items[currentIndex]}</article>
        </div>
        <button
          className={cx({
            button: true,
            prev: true,
            disabled: currentIndex === 0,
          })}
          onFocus={this.showPrev}
          onBlur={this.hidePrev}
          onMouseOver={this.showPrev}
          onMouseOut={this.hidePrev}
          onClick={this.prev}
        >
          <span className="is-sr-only">Prev</span>
          <i />
        </button>
        <PreviewFigure
          initialPose="off"
          pose={prevItem && this.state.prevShowing ? "on" : "off"}
          className={cx({ preview: true, prev: true })}
        >
          <h3
            dangerouslySetInnerHTML={{
              __html: this.renderName(prevItem ? prevItem.props : null),
            }}
          />
          {this.renderImage(prevItem ? prevItem.props : null)}
        </PreviewFigure>
        <PreviewFigure
          initialPose="off"
          pose={nextItem && this.state.nextShowing ? "on" : "off"}
          className={cx({ preview: true, next: true })}
        >
          <h3
            dangerouslySetInnerHTML={{
              __html: this.renderName(nextItem ? nextItem.props : null),
            }}
          />
          {this.renderImage(nextItem ? nextItem.props : null)}
        </PreviewFigure>
        <button
          className={cx({
            button: true,
            next: true,
            disabled: currentIndex === items.length - 1,
          })}
          onFocus={this.showNext}
          onBlur={this.hideNext}
          onMouseOver={this.showNext}
          onMouseOut={this.hideNext}
          onClick={this.next}
        >
          <span className="is-sr-only">Next</span>
          <i />
        </button>
      </React.Fragment>
    );
  }
}

export default DialogCarousel;
