import React from 'react';
import { renderText, renderHtml, PrismicLink } from 'utils/renderHelpers';
import Section from 'react-bulma-components/lib/components/section';
import Columns from 'react-bulma-components/lib/components/columns';
import Container from 'react-bulma-components/lib/components/container';
import styles from './Quote.module.scss';
import posed from 'react-pose';
import Triangles from 'components/Triangles';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

const Person = posed.h3({
  exit: {
    opacity: 0,
    transition: {
      duration: 300
    }
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 400,
      delay: 100
    }
  }
});

const AnimatedButton = posed.div({
  exit: {
    opacity: 0,
    transition: {
      duration: 300
    }
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 400,
      delay: 500
    }
  }
});

export const QuoteContent = ({ primary, isAnimated, compressed }) => {
  const { callout_label, callout_link, caption, content, person } = primary;
  if (
    content.html &&
    content.html.length > 0 &&
    content.html.substr(0, 2) === '<p'
  ) {
    content.html = content.html.substr(3);
    content.html = content.html.substr(0, content.html.length - 4);
  }
  return (
    <React.Fragment>
      {!isAnimated && content.html && content.html.length > 0
        ? renderHtml(
            content,
            'blockquote',
            cx({
              blockquote: true,
              compressed: compressed === true,
              addQuotes: true
            }),
            null,
            null,
            null,
            null,
            isAnimated === true,
            {
              initialPose: 'exit',
              charPoses: {
                exit: {
                  opacity: 0,
                  y: 30,
                  transition: {
                    duration: 300
                  }
                },
                enter: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 400,
                    delay: 300
                  }
                }
              }
            }
          )
        : renderText(
            content,
            'blockquote',
            cx({
              blockquote: true,
              compressed: compressed === true,
              addQuotes: false
            }),
            null,
            null,
            '“',
            '”',
            isAnimated === true,
            {
              initialPose: 'exit',
              charPoses: {
                exit: {
                  opacity: 0,
                  y: 30,
                  transition: {
                    duration: 300
                  }
                },
                enter: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 400,
                    delay: 300
                  }
                }
              }
            }
          )}
      {person && (
        <div className={styles.person}>
          {isAnimated ? (
            <Person initialPose={'exit'}>{person.text}</Person>
          ) : (
            renderText(person, `h3`)
          )}
          <br />
          {renderText(
            caption,
            'span',
            cx({ compressed: compressed === true }),
            null,
            null,
            null,
            null,
            isAnimated === true,
            {
              initialPose: 'exit',
              charPoses: {
                exit: {
                  opacity: 0,
                  transition: {
                    duration: 300
                  }
                },
                enter: {
                  opacity: 1,
                  transition: {
                    duration: 400,
                    delay: 250
                  }
                }
              }
            }
          )}
        </div>
      )}
      {callout_link ? (
        isAnimated ? (
          <AnimatedButton initialPose="exit">
            <PrismicLink
              link={callout_link}
              label={callout_label}
              className={styles.button}
            />
          </AnimatedButton>
        ) : (
          <PrismicLink
            link={callout_link}
            label={callout_label}
            className={styles.button}
          />
        )
      ) : null}
    </React.Fragment>
  );
};

export const QuoteBody = ({
  primary,
  compressed = false,
  size = { mobile: 9, tablet: 7 },
  showTriangles = false,
  isAnimated = false
}) => {
  return (
    <React.Fragment>
      <Columns.Column
        mobile={{ size: size.mobile }}
        tablet={{ size: size.tablet }}
      >
        <QuoteContent
          primary={primary}
          isAnimated={isAnimated === true}
          compressed={compressed}
        />
      </Columns.Column>
      {showTriangles && (
        <Columns.Column
          mobile={{ size: 12 - size.mobile }}
          tablet={{ size: 12 - size.tablet }}
          style={{ position: `relative`, display: `flex` }}
        >
          <figure className={styles.trianglesWrapper}>
            <Triangles
              theme="red"
              animationType="ltr"
              percentageChanceofNoTriangle={80}
            />
          </figure>
        </Columns.Column>
      )}
    </React.Fragment>
  );
};

export default ({ primary }) => {
  const theme = primary.theme || `black`;
  return (
    <Section className={`${theme} ${cx({ quote: true, [theme]: true })}`}>
      <Container>
        <Columns mobile multiline>
          <QuoteBody
            primary={primary}
            showTriangles={true}
            isAnimated={false}
          />
        </Columns>
      </Container>
    </Section>
  );
};
