import React, { Component } from "react";

import PropTypes from "prop-types";
import axios from "axios";
import classNames from "classnames/bind";
import constants from "utils/constants";
import loadYoutubeAPI from "utils/loadYoutubeAPI";
import styles from "./YTPlayer.module.scss";

const { youtubeApiKey } = constants;
let cx = classNames.bind(styles);

export default class YTPlayer extends Component {
  static INDEX = 0;
  static propTypes = {
    videoId: PropTypes.string.isRequired,
    onPlayVideo: PropTypes.func,
    onStopVideo: PropTypes.func,
    autoPlay: PropTypes.bool,
  };

  static defaultProps = {
    onPlayVideo: null,
    onStopVideo: null,
    autoPlay: false,
  };

  constructor(props) {
    super(props);
    this.disabled = false;

    this.state = {
      currentVideo: null,
      id: null,
      videoId: props.videoId,
      ready: false,
      playing: false,
      overlayVisible: true,
      thumbnail: false,
    };
  }

  componentDidMount() {
    YTPlayer.INDEX++;
    this.setState({ id: `player-${YTPlayer.INDEX}` }, () => {
      loadYoutubeAPI(this.createPlayer, this);
    });
  }

  componentWillUnmount() {
    this.disabled = true;
  }

  createPlayer = () => {
    console.log(this.props, "create");
    this.loadVideo();
  };

  getThumbnail = (thumbnails) => {
    if (!thumbnails) {
      return null;
    }
    //let {default, high, maxres, medium, standard} = thumbnails;
    return (
      thumbnails.maxres ||
      thumbnails.high ||
      thumbnails.medium ||
      thumbnails.standard ||
      thumbnails.default
    );
  };

  loadVideo() {
    const isList = this.props.isList === true;
    if (this.disabled) {
      return;
    }

    const youTubeURL = `https://www.googleapis.com/youtube/v3/${
      isList ? "playlistItems" : "videos"
    }?${isList ? "playlistId" : "id"}=${
      this.props.videoId
    }&key=${youtubeApiKey}&fields=items(id,snippet(title,description,publishedAt,thumbnails),contentDetails)&part=snippet,contentDetails`;

    const playerVars = isList
      ? {
          modestbranding: 1,
          rel: 0,
          autoplay: this.props.autoPlay === true ? 1 : 0,
          listType: "playlist",
          list: this.props.videoId,
        }
      : {
          modestbranding: 1,
          rel: 0,
          autoplay: this.props.autoPlay === true ? 1 : 0,
        };

    axios.get(youTubeURL).then((response) => {
      if (this.disabled) {
        return;
      }
      let thumbnails = response.data?.items[0]?.snippet?.thumbnails;

      if (this.props.setVideoData) {
        this.props.setVideoData(response.data.items[0]);
      }
      this.setState(
        {
          currentVideo: response.data.items[0],
          thumbnail: this.getThumbnail(thumbnails),
        },
        () => {
          new window.YT.Player(this.state.id, {
            videoId: this.props.videoId,
            width: `100%`,
            playerVars,
            events: {
              onReady: this.onPlayerReady,
              onStateChange: this.onPlayerStateChange,
            },
            origin: window.location.href,
          });
        }
      );
    });
  }

  onPlayerReady = (e) => {
    if (this.disabled) {
      return;
    }
    this.player = e.target;
    this.setState({ ready: true });
  };

  onPlayerStateChange = (e) => {
    switch (e.data) {
      case window.YT.PlayerState.PLAYING:
        this.setState({ playing: true }, () => {
          setTimeout(() => {
            this.setState({ overlayVisible: false });
          }, 450);
          if (this.props.onPlayVideo) {
            this.props.onPlayVideo();
          }
        });
        break;
      case window.YT.PlayerState.PAUSED:
        if (this.props.onStopVideo) {
          this.props.onStopVideo();
        }
        this.setState({ playing: false });
        break;
      case window.YT.PlayerState.ENDED:
        if (this.props.onEndVideo) {
          this.props.onEndVideo();
        }
        this.setState({ playing: false });
        break;
      default:
        break;
    }
  };

  playVideo = () => {
    if (this.disabled) {
      return;
    }
    this.player.playVideo();
  };

  componentDidUpdate() {
    if (this.player && this.props.stopped && this.state.playing) {
      this.player.pauseVideo();
    }
    if (
      this.player &&
      !this.props.stopped &&
      this.props.autoPlay &&
      !this.state.playing
    ) {
      this.player.playVideo();
    }
  }

  shouldComponentUpdate(props, state) {
    return (
      this.props.stopped !== props.stopped ||
      this.props.autoPlay !== props.autoPlay ||
      this.state.ready !== state.ready ||
      this.state.thumbnail !== state.thumbnail ||
      this.state.playing !== state.playing ||
      this.state.overlayVisible !== state.overlayVisible ||
      this.state.currentVideo !== state.currentVideo
    );
  }

  render() {
    if (this.disabled) {
      return null;
    }
    let { id, thumbnail, ready, overlayVisible, playing } = this.state;
    return (
      <React.Fragment>
        <article className={styles.player}>
          <div id={id} />
          {overlayVisible && (
            <div className={cx({ overlay: true, disabled: playing })}>
              {thumbnail && (
                <figure>
                  <img src={thumbnail.url} alt={`Video Thumbnail`} />
                </figure>
              )}
              <button
                className={cx({
                  playButton: true,
                  disabled: !ready,
                })}
                onClick={this.playVideo}
              >
                <span className="is-sr-only">Play</span>
                <i>
                  <span className={styles.triangle} />
                </i>
              </button>
            </div>
          )}
        </article>
      </React.Fragment>
    );
  }
}
